<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 23.3477C3 20.2261 5.53057 17.6956 8.65218 17.6956V17.6956C11.7738 17.6956 14.3044 20.2261 14.3044 23.3477V23.3477C14.3044 26.4694 11.7738 28.9999 8.65218 28.9999V28.9999C5.53057 28.9999 3 26.4694 3 23.3477V23.3477Z"
      stroke="black"
      stroke-width="1.5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.56543 4.13037H13.7394V14.3043H3.56543V4.13037Z"
      stroke="black"
      stroke-width="1.5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.6963 18.1479H27.8702V28.3219H17.6963V18.1479Z"
      stroke="black"
      stroke-width="1.5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.0664 4L28.5664 14H17.5664L23.0664 4Z"
      stroke="black"
      stroke-width="1.5"
    />
  </svg>
</template>
