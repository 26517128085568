
import { defineComponent } from "vue";
import PricingTime from "@/components/atoms/icon/PricingTime/PricingTime.vue";
import PricingService from "@/components/atoms/icon/PricingService/PricingService.vue";
import PricingEtc from "@/components/atoms/icon/PricingEtc/PricingEtc.vue";
import CheckIconBlue from "@/components/atoms/icon/CheckIconBlue/CheckIconBlue.vue";
import GuideFaqMobile from "@/components/templates/GuideFaq/GuideFaqMobile.vue";
import { data } from "@/shared/pricing";

export default defineComponent({
  name: "PricingDeveloperMobile",
  components: {
    PricingTime,
    PricingService,
    PricingEtc,
    CheckIconBlue,
    GuideFaqMobile
  },
  setup() {
    const { faq } = data;
    return {
      faq
    };
  }
});
