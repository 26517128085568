<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="29"
      width="26"
      height="26"
      rx="13"
      transform="rotate(-90 3 29)"
      stroke="black"
      stroke-width="1.5"
    />
    <path
      d="M21.9072 16.4321L15.9574 16.4321L15.9574 8.43213"
      stroke="black"
      stroke-width="1.5"
    />
  </svg>
</template>
