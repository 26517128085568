export const data = {
  sitemapTitle: "요금제 - 카카오 i 커넥트 라이브",
  caption: "상품 상세 비교",
  title: "무료로 시작하고, 서비스 성장에 따라<br>이용플랜을 선택하세요",
  description:
    "카카오 i 커넥트 라이브는 개발자 생태계를 존중하고 지원합니다.<br>무료로 콘솔에 가입하여, 부담없이 자유롭게 개발하세요.",
  payment: "* 월별 청구 / VAT별도",
  mobile: {
    caption: "상품 상세 비교",
    title: "무료로 시작하고,<br>서비스 성장에 따라<br>이용플랜을 선택하세요",
    description: [
      "카카오 i 커넥트 라이브는 개발자 생태계를 존중하고 지원합니다.<br>무료로 콘솔에 가입하여, 부담없이 자유롭게 개발하세요."
    ]
  },
  faq: {
    title: "자주 묻는 질문(도입)",
    list: [
      {
        question: "카카오 i 커넥트 라이브를 무료로 이용할 수 있나요?",
        answer:
          "Developer Plan을 통해 이용하실 수 있으며, 별도 계약이 필요하지 않습니다."
      },
      {
        question: "자동이체나 카드결제가 가능한가요?",
        answer:
          "카카오 i 커넥트는 매월 초 세금계산서를 발행해 드리고 있으며, 사용월 요금을 확인 후 현금 계좌 이체만 가능합니다."
      },
      {
        question: "계약 이후 중도 플랜 변경이 가능한가요?",
        answer:
          "사용량 추이에 따라 상위 요금제로 변경 신청하실 수 있으며, 카카오엔터프라이즈 계약담당자와 논의 부탁드립니다."
      },
      {
        question:
          "기존 리몬트몬스터 SDK 를 사용중에 있습니다. 카카오 i 커넥트 라이브와 다른점이 있나요?",
        answer:
          "카카오 i 커넥트 라이브는 리모트몬스터 방송통신 API 서비스와 개발 방법, 사업 정책이 상이합니다.<br>이전이 필요하시면 <a href='mailto://c.live@kakaoenterprise.com'>c.live@kakaoenterprise.com</a> 또는 도입문의로 연락 부탁드립니다."
      }
    ]
  }
};
