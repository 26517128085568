import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bd491d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contents-wrap" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "faq-list" }
const _hoisted_4 = { class: "question" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "answer" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.data.title), 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.list, (item, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h4", {
              class: "question-text",
              innerHTML: item.question
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", {
              class: "answer-text",
              innerHTML: item.answer
            }, null, 8, _hoisted_7)
          ])
        ]))
      }), 128))
    ])
  ]))
}