<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style="mix-blend-mode:multiply">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 4H28V28H4V4Z"
        stroke="black"
        stroke-width="1.5"
      />
    </g>
    <path d="M11 16H21M16 21L16 11" stroke="black" stroke-width="1.5" />
  </svg>
</template>
